











import {
  Component,
} from 'vue-property-decorator';
import FormBase from '../FormBase';
import RadialChartData from './modelsRadial';

@Component
export default class FormBar extends FormBase {
  data: RadialChartData = {
    title: '',
    description: '',
    icon: '',
    valueField: '',
    targetField: '',
    label: '',
  };
}
